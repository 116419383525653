<template>
    <div class="classify-all">
        <div class="classify-search">
        <img src="@/assets/classify/logo.png" alt @click="$router.push('/zh')" />
        <router-link to="/zh/search">
            <img src="@/assets/index/Search@2x.png" alt />
            <span>请输入关键词</span>
        </router-link>
        </div>
        <div class="classify-main">
            <div class="main-left">
                <div
                :class="index == activeIndex ? 'active left-item' : 'left-item'"
                v-for="(item, index) in classifyList"
                :key="index"
                @click="activeIndex = index"
                >
                <div class="item-name">{{item.title}}</div>
                </div>
            </div>
            <div class="main-right m-scroll" ref="scroll" @scroll="handleScroll">
                <transition name="van-fade">
                    <div class="flex-sub-classify m-scroll" v-show="isShowSubClassify">
                        <div class="flex-sub-item" v-for="(item, index) in subClassifyList" :key="index"
                        @click="$router.push(`/zh/subclassify?id=${item.id}&title=${item.title}`)">
                            <img  :src="item.pic_url" alt="">
                        </div>
                    </div>
                </transition>
                <img v-if="classifyList[activeIndex]" :src="classifyList[activeIndex].pic_url" alt />
                <div class="sub-classify" ref="subClassify">
                    <div class="sub-item" v-for="(item, index) in subClassifyList" :key="index"
                    @click="$router.push(`/zh/subclassify?id=${item.id}&title=${item.title}`)">
                        <img :src="item.pic_url" alt />
                        <p>{{item.title}}</p>
                    </div>
                </div>
                <m-divider title="品类好货" class="m-index-margin"></m-divider>
                <ul class="m-goods-list">
                <li class="m-goods-box" v-for="(item, index) in goodsList" :key="index">
                    <router-link :to="{path: `/zh/goods/detail/${item.id}`, query: {pre: (item.merchant_id == 1 && item.pre_info.is_pre == 1) ? 1 : 0}}">
                    <img :src="item.image_url" alt="goods" />
                    <div class="eleven" v-if="item.merchant_id == 1 && item.pre_info.is_pre == 0 && elevenShow"></div>
                    </router-link>
                    <p class="van-multi-ellipsis--l2">
                        <span class="m-mooby" v-if="item.merchant_id == 1 && item.pre_info.is_pre == 0">自营</span>
                        <span class="m-mooby"
                        v-if="item.merchant_id == 1 && item.pre_info.is_pre == 1">预售</span>
                        {{item.goods_info.name}}
                        <span>{{item.goods_info.name}}</span>
                    </p>
                    <div class="goods-price">
                        <div>
                            <div class="is_pre" v-if="item.pre_info.is_pre == 1 && item.pre_info.pre_min_price != null">
                                <p>
                                    <span>$</span>
                                    <span>{{item.pre_info.pre_min_price}}</span>
                                </p>
                            </div>
                            <div class="is_pre" v-else>
                                <p>
                                    <span>$</span>
                                    <span>{{(item.activity_min_price *1).toFixed(0) != 0 ? item.activity_min_price : (item.min_price * (item.off == 0 ? 1 : item.off / 100)).toFixed(2)}}</span>
                                </p>
                                <p v-if="(item.activity_min_price *1).toFixed(0) != 0 ? true : item.off != 0">${{item.min_price}}</p>
                            </div>
                        </div>
                        <img @click="e => handleCart(item.id, e)" src="@/assets/index/iocn-gouwu@2x.png" alt="cart" />
                    </div>
                </li>
                <p class="search-no" v-if="noMore">没有更多了</p>
                </ul>
            </div>
        </div>
        
        <!-- 选择SKU 加入购物车 -->
        <m-sku title="加入购物车" :show="skuModal" @close="val => skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

        <!-- 加入购物车动画 -->
        <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>

        <FooterBar  />
    </div>
</template>

<style scoped lang="less">
.classify-all {
    width: 100%;
    height: 100%;
    padding-bottom: 60px;
    .classify-search {
        width: 375px;
        height: 44px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 1px 0px 0px rgba(240, 240, 240, 1);
        display: flex;
        > img {
            width: 28px;
            height: 24px;
            margin: 10px 0 10px 16px;
        }
        a {
            display: block;
            width: 303px;
            height: 32px;
            background: rgba(250, 250, 250, 1);
            border-radius: 20px;
            margin: 6px 0 0 15px;
            > img {
                width: 18px;
                height: 18px;
                margin: 7px 0 7px 10px;
            }
            span {
                font-size: 14px;
                
                font-weight: 400;
                color: #888;
                line-height: 20px;
                vertical-align: 11px;
                margin-left: 10px;
            }
        }
    }
    .classify-main {
        width: 100%;
        height: calc(100% - 44px);
        display: flex;
        .main-left {
            min-width: 92px;
            height: 100%;
            overflow-y: auto;
            .left-item {
                width: 100%;
                height: 50px;
                padding-top: 15px;
                box-sizing: border-box;
                .item-name {
                width: 76px;
                height: 24px;
                margin: 0 8px;
                text-align: center;
                line-height: 24px;
                color: #222;
                border-radius: 12px;
                font-size: 14px;
                }
            }
            .active {
                .item-name {
                    background: linear-gradient(
                        315deg,
                        rgba(240, 60, 24, 1) 0%,
                        rgba(240, 100, 72, 1) 100%
                    );
                    color: #fff;
                }
            }
        }
        .main-right {
            flex: 1;
            height: 100%;
            padding: 14px;
            overflow-y: auto;
            position: relative;
            > img {
                width: 255px;
                height: 74px;
                object-fit: cover;
            }
            .sub-classify {
                width: 100%;
                overflow: hidden;
                margin-top: 10px;
                .sub-item {
                    width: 50px;
                    height: 75px;
                    float: left;
                    margin: 6px 17px;
                    > img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                    }
                    p {
                        text-align: center;
                        font-size: 11px;
                        color: #111;
                    }
                }
            }
            .flex-sub-classify{width:calc(100% - 92px);height:53px;background-color: #fff;position: fixed;transform: translate(0, -14px);
            overflow-x: auto;white-space: nowrap;
                .flex-sub-item{width:38px;height:38px;margin:6px 10px 0 10px;display: inline-block;border-radius: 50%;overflow: hidden;
                    img{width:100%;height:100%;object-fit: cover}
                }
            }
            .m-index-margin {
                margin: 20px 0 15px 0;
            }
        }
    }
    .m-goods-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .m-goods-box {
            position: relative;
            background: #fff;
            width: 122px;
            box-shadow: 0px 10px 15px 0px rgba(217, 217, 217, 0.15);
            border-radius: 5px;
            margin-bottom: 16px;
            & > a > img {
                width: 122px;
                height: 120px;
                border-radius: 5px 5px 0px 0px;
            }
            &>a >.eleven {
                position: absolute;
                top: 0;
                left: 0;
                width: 122px;
                height: 120px;
                border-radius: 5px 5px 0px 0px;
                background: url('../../../assets/index/eleven.png') no-repeat center;
                background-size: 100% 100%;
            }
            & > p {
                height:36px;
                font-size: 12px;
                letter-spacing: 0.31px;
                line-height: 18px;
            }
            .goods-price {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 10px 0 6px 0;
                font-size: 12px;
                & > div {
                    .is_pre {
                        & > p:nth-of-type(1) {
                            color: #ed2a24;
                            & > span:nth-of-type(2) {
                            font-size: 16px;
                            }
                        }
                        & > p:nth-of-type(2) {
                            margin-top: 2px;
                            color: #999999;
                            text-decoration: line-through;
                        }
                    }
                    
                }
                & > img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .m-goods-box:nth-child(odd) {
            margin-right: 10px;
        }
    }
}
.search-no {
    line-height:45px;
    text-align:center;
    font-size:12px;
    color:#888;
    width:100%;
}
</style>

<script>
import MDivider     from "@/components/zh/m-divider.vue";
import FooterBar    from '@/components/zh/footer-bar.vue'
import MSku         from '@/components/zh/m-sku.vue'
import MAnimate     from '@/components/zh/cart-animate.vue'

import { getClassifyList, getClassifyGoods, getClassifyLists } from '@/api/zh/classify.js'
import MixinScroll from '@/untils/js/mixin-scroll.js'
export default {
    mixins: [ MixinScroll ],
    components: { MDivider, FooterBar, MSku, MAnimate },
    data() {
        return {
            activeIndex: 0,
            isShowSubClassify: false,
            subClassifyTop: 300,
            classifyList: [],
            subClassifyList: [],
            goodsList: [],
            start: false,      // 是否开启加入购物车动画
            start_dom: null,   // 开启动画的起点DOM 
            skuModal: false,    // 控制显示 选择sku加入购物车面板
            page: 1,
            getLock: false,
            noMore: false,
            goodsId: '',
            elevenShow: true // 圣诞活动边框显示
        };
    },
    methods: {
        // 内容滑动事件
        handleScroll(e){
            if(e.target.scrollTop > (this.subClassifyTop - 50)){
                this.isShowSubClassify = true
            }else{
                this.isShowSubClassify = false
            }

            if(e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop < 200 && !this.getLock && !this.noMore) {
                this.page++
                this.getClassifyGoodsList()
            }
        },
        getSubClassifyTop() {
            this.subClassifyTop = this.$refs.subClassify.offsetTop + this.$refs.subClassify.offsetHeight
        },
        handleCart(goods, e){
            // 商品列表购物车点击事件
            this.goodsId = goods
            this.skuModal  = true
            this.start_dom = e 
            this.startAnimat = true
        },
        handleCartSuccess(){
            // sku 弹窗加入购物车成功回调
            this.skuModal = false
            if(this.startAnimat) {
                let timer = setTimeout( () => {
                    this.start = true
                    clearTimeout(timer)
                },300)
            }        
        },
        handleCartEnd(){
            // 加入购物车动画结束
            this.start = false
        },
        getClassifyListHandle() {
            getClassifyList().then(res => {

                 if (this.$store.state.isMiniProgram) {
                    let Data = res.data.filter(item => {
                        // console.log(item);
                         return item.id != 10 && item.id != 12 && item.id != 14 && item.id != 16
                     });
                     this.classifyList = Data
                }else{
                    this.classifyList = res.data
                }
                this.goodsList = []
                this.page = 1
                this.getLock = false
                this.noMore = false
                this.getClassifyGoodsList()
                this.getClassifyListHandles()
                setTimeout(() => {
                    this.getSubClassifyTop()
                }, 200)
                
            })
        },
        // 二级分类
        getClassifyListHandles() {
            getClassifyLists(this.classifyList[this.activeIndex].id).then(res => {
                if(res) {
                    this.subClassifyList = res.data
                }
            })
        },
        getClassifyGoodsList() {
            // 获取分类下的商品
            this.getLock = true
            getClassifyGoods({type_id: this.classifyList[this.activeIndex].id, status: 4, page: this.page}).then(res => {
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                }else{
                    this.noMore = true
                }
            }).finally(() => {
                this.getLock = false
            })
        }
    },
    mounted() {
        this.getSubClassifyTop()
        this.getClassifyListHandle()
    },
    watch: {
        activeIndex(val) {
            this.subClassifyList = this.classifyList[val].subclass
            this.goodsList = []
            this.page = 1
            this.getLock = false
            this.noMore = false
            this.getClassifyGoodsList()
            this.getClassifyListHandles()
            setTimeout(() => {
                this.getSubClassifyTop()
            }, 200)
        }
    }
};
</script>